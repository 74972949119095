"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _victoryArea = require("victory-area");

Object.keys(_victoryArea).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryArea[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryArea[key];
    }
  });
});

var _victoryAxis = require("victory-axis");

Object.keys(_victoryAxis).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryAxis[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryAxis[key];
    }
  });
});

var _victoryBar = require("victory-bar");

Object.keys(_victoryBar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryBar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryBar[key];
    }
  });
});

var _victoryBoxPlot = require("victory-box-plot");

Object.keys(_victoryBoxPlot).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryBoxPlot[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryBoxPlot[key];
    }
  });
});

var _victoryBrushContainer = require("victory-brush-container");

Object.keys(_victoryBrushContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryBrushContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryBrushContainer[key];
    }
  });
});

var _victoryBrushLine = require("victory-brush-line");

Object.keys(_victoryBrushLine).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryBrushLine[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryBrushLine[key];
    }
  });
});

var _victoryCandlestick = require("victory-candlestick");

Object.keys(_victoryCandlestick).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryCandlestick[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryCandlestick[key];
    }
  });
});

var _victoryCanvas = require("victory-canvas");

Object.keys(_victoryCanvas).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryCanvas[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryCanvas[key];
    }
  });
});

var _victoryChart = require("victory-chart");

Object.keys(_victoryChart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryChart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryChart[key];
    }
  });
});

var _victoryCore = require("victory-core");

Object.keys(_victoryCore).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryCore[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryCore[key];
    }
  });
});

var _victoryCreateContainer = require("victory-create-container");

Object.keys(_victoryCreateContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryCreateContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryCreateContainer[key];
    }
  });
});

var _victoryCursorContainer = require("victory-cursor-container");

Object.keys(_victoryCursorContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryCursorContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryCursorContainer[key];
    }
  });
});

var _victoryErrorbar = require("victory-errorbar");

Object.keys(_victoryErrorbar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryErrorbar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryErrorbar[key];
    }
  });
});

var _victoryGroup = require("victory-group");

Object.keys(_victoryGroup).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryGroup[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryGroup[key];
    }
  });
});

var _victoryHistogram = require("victory-histogram");

Object.keys(_victoryHistogram).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryHistogram[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryHistogram[key];
    }
  });
});

var _victoryLegend = require("victory-legend");

Object.keys(_victoryLegend).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryLegend[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryLegend[key];
    }
  });
});

var _victoryLine = require("victory-line");

Object.keys(_victoryLine).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryLine[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryLine[key];
    }
  });
});

var _victoryPie = require("victory-pie");

Object.keys(_victoryPie).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryPie[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryPie[key];
    }
  });
});

var _victoryPolarAxis = require("victory-polar-axis");

Object.keys(_victoryPolarAxis).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryPolarAxis[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryPolarAxis[key];
    }
  });
});

var _victoryScatter = require("victory-scatter");

Object.keys(_victoryScatter).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryScatter[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryScatter[key];
    }
  });
});

var _victorySelectionContainer = require("victory-selection-container");

Object.keys(_victorySelectionContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victorySelectionContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victorySelectionContainer[key];
    }
  });
});

var _victorySharedEvents = require("victory-shared-events");

Object.keys(_victorySharedEvents).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victorySharedEvents[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victorySharedEvents[key];
    }
  });
});

var _victoryStack = require("victory-stack");

Object.keys(_victoryStack).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryStack[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryStack[key];
    }
  });
});

var _victoryTooltip = require("victory-tooltip");

Object.keys(_victoryTooltip).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryTooltip[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryTooltip[key];
    }
  });
});

var _victoryVoronoi = require("victory-voronoi");

Object.keys(_victoryVoronoi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryVoronoi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryVoronoi[key];
    }
  });
});

var _victoryVoronoiContainer = require("victory-voronoi-container");

Object.keys(_victoryVoronoiContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryVoronoiContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryVoronoiContainer[key];
    }
  });
});

var _victoryZoomContainer = require("victory-zoom-container");

Object.keys(_victoryZoomContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _victoryZoomContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _victoryZoomContainer[key];
    }
  });
});