import {
  Circle,
  ClipPath,
  Defs,
  Ellipse,
  FeBlend,
  FeColorMatrix,
  FeComponentTransfer,
  FeComposite,
  FeConvolveMatrix,
  FeDiffuseLighting,
  FeDisplacementMap,
  FeDistantLight,
  FeDropShadow,
  FeFlood,
  FeGaussianBlur,
  FeImage,
  FeMerge,
  FeMergeNode,
  FeMorphology,
  FeOffset,
  FePointLight,
  FeSpecularLighting,
  FeSpotLight,
  FeTile,
  FeTurbulence,
  Filter,
  ForeignObject,
  G,
  Image,
  Line,
  LinearGradient,
  Marker,
  Mask,
  Path,
  Pattern,
  Polygon,
  Polyline,
  RadialGradient,
  Rect,
  Stop,
  Svg,
  Symbol,
  Text,
  TextPath,
  TSpan,
  Use,
} from './elements';

export const tags = {
  circle: Circle,
  clipPath: ClipPath,
  defs: Defs,
  ellipse: Ellipse,
  filter: Filter,
  feBlend: FeBlend,
  feColorMatrix: FeColorMatrix,
  feComponentTransfer: FeComponentTransfer,
  feComposite: FeComposite,
  feConvolveMatrix: FeConvolveMatrix,
  feDiffuseLighting: FeDiffuseLighting,
  feDisplacementMap: FeDisplacementMap,
  feDistantLight: FeDistantLight,
  feDropShadow: FeDropShadow,
  feFlood: FeFlood,
  feGaussianBlur: FeGaussianBlur,
  feImage: FeImage,
  feMerge: FeMerge,
  feMergeNode: FeMergeNode,
  feMorphology: FeMorphology,
  feOffset: FeOffset,
  fePointLight: FePointLight,
  feSpecularLighting: FeSpecularLighting,
  feSpotLight: FeSpotLight,
  feTile: FeTile,
  feTurbulence: FeTurbulence,
  foreignObject: ForeignObject,
  g: G,
  image: Image,
  line: Line,
  linearGradient: LinearGradient,
  marker: Marker,
  mask: Mask,
  path: Path,
  pattern: Pattern,
  polygon: Polygon,
  polyline: Polyline,
  radialGradient: RadialGradient,
  rect: Rect,
  stop: Stop,
  svg: Svg,
  symbol: Symbol,
  text: Text,
  textPath: TextPath,
  tspan: TSpan,
  use: Use,
} as const;
